import { TouchEvent, useRef } from 'react'

interface IReturn {
  onTouchStart: (e: TouchEvent<HTMLDivElement>) => void
  onTouchMove: (e: TouchEvent<HTMLDivElement>) => void
  onTouchEnd: (_e: any) => void
}

export function useSwipe(
  onLeftSwipe: () => void,
  onRightSwipe: () => void,
  swipedComponentWillRerender: boolean
): IReturn {
  const initialTouchX = useRef<number>()
  const touchRegistered = useRef<boolean>(false)

  function handleTouchStart(e: TouchEvent<HTMLDivElement>) {
    initialTouchX.current = e.nativeEvent.touches[0].clientX
  }

  function handleTouchMove(e: TouchEvent<HTMLDivElement>) {
    if (
      !initialTouchX.current ||
      (!swipedComponentWillRerender && touchRegistered.current === true)
    ) {
      return
    }

    const movedTouchX = e.nativeEvent.touches[0].clientX
    const direction = initialTouchX.current - movedTouchX

    if (direction < 0) {
      onRightSwipe()
    } else {
      onLeftSwipe()
    }

    touchRegistered.current = true
  }

  function handleTouchEnd(_e: any) {
    touchRegistered.current = false
  }

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  }
}
