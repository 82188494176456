import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { IHomePageQuery, ISanityPhotoMin } from '../../@types'
import { PageTitles } from '../../vars'
import { Layout } from '../common/Layout'
import { Footer } from './Footer'
import { HeroSection } from './HeroSection'
import { Introduction } from './Introduction'
import { Showcase } from './Showcase'
import { Backdrop, Background, Container } from './styles'

interface IWindow {
  previousPath: undefined | string
}

const windowGlobal: IWindow | false = (typeof window !== 'undefined' &&
                                       window) as IWindow | false

const documentGlobal: Document | false =
        typeof document !== 'undefined' && document

interface IProps {
  data: IHomePageQuery
}

export function Home({ data }: IProps) {
  if (!documentGlobal || !windowGlobal) {
    return null
  }

  const dontAnimate = windowGlobal.previousPath !== undefined

  const {
          fluid: backgroundAsset,
        }                        = data.sanityLandingPageBackground.image.asset
  const { fluid: portraitAsset } = data.sanityIntroPhoto.introPhoto.asset

  const showcasePhotos: ISanityPhotoMin[] = data.allSanityPhoto.nodes

  const [showContent, setShowContent] = useState(dontAnimate)

  const timeoutRef = useRef<NodeJS.Timeout | number>()

  function handleLoad() {
    timeoutRef.current = setTimeout(() => {
      setShowContent(true)
    }, 1500)
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout | number

    if (!showContent && !dontAnimate) {
      timeout = setTimeout(() => {
        if (!showContent) {
          setShowContent(true)
        }
      }, 3000)
    }

    return () => {
      if (
        typeof timeout === 'number' &&
        typeof timeoutRef.current === 'number'
      ) {
        clearTimeout(timeout)
        clearTimeout(timeoutRef.current)
      }
    }
  }, [showContent, dontAnimate])

  const opacity = showContent ? 0.5 : 1

  const {
          siteDescription,
          siteHeader,
          mainCTAText,
          introductionDescription,
          introductionCTAText,
          introductionHeader,
          recentPhotosHeader,
          recentPhotosCTAText,
        } = data.sanitySiteCopy

  return (
    <>
      <Layout
        currentPageTitle={PageTitles.Home}
        showContent={showContent}
        darkBg={true}
      >
        <Container showContent={showContent}>
          <HeroSection {...{ siteDescription, siteHeader, mainCTAText }} />

          <Introduction
            portrait={portraitAsset}
            {...{
              introductionHeader,
              introductionDescription,
              introductionCTAText,
            }}
          />

          <Showcase
            showcaseAssets={showcasePhotos}
            {...{
              recentPhotosCTAText,
              recentPhotosHeader,
            }}
          />

          <Footer />
        </Container>
      </Layout>
      {ReactDOM.createPortal(
        <>
          <Background
            fluid={backgroundAsset}
            alt='Background image'
            onLoad={dontAnimate ? undefined : handleLoad}
            preserveStackingContext={true}
            style={{
              position: 'fixed',
              opacity,
            }}
          />
          <Backdrop />
        </>,
        documentGlobal.body,
      )}
    </>
  )
}
