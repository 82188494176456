import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import { ButtonLink } from '../../../globalStyles'
import { rhythm } from '../../../utils/typography'
import { mediaQueries } from '../../../vars'
import { tan10Deg } from '../styles'

export const Background = styled(BackgroundImage)`
  margin-top: -${rhythm(1)};
  margin-bottom: calc(100vw * ${tan10Deg} * 2);
  height: 60vh;
  width: 100vw;
  position: relative;
  background-size: 100%;
  background-position: bottom right;
  transform: skewY(10deg);
  transform-origin: top left;
  background-color: var(--white-tl);
  padding-top: calc(100vw * ${tan10Deg});

  ::before,
  ::after {
    transform: skewY(-10deg);
    background-color: transparent !important;
    height: calc(100% + (100vw * ${tan10Deg} / 2)) !important;
  }

  @media ${mediaQueries.landscape.handheld}, ${mediaQueries.landscape.tablet} {
    padding-top: calc(100vw * ${tan10Deg} / 2);

    ::before,
    ::after {
      background-size: 80% !important;
    }
  }

  @media ${mediaQueries.landscape.handheld} {
    height: 100vh;
  }

  @media ${mediaQueries.landscape.tablet}, ${mediaQueries.general.desktop} {
    height: 80vh;
    padding-top: calc(100vw * ${tan10Deg});
  }

  @media ${mediaQueries.general.desktop} {
    ::before,
    ::after {
      background-size: 60% !important;
    }
  }
`

export const Content = styled.section`
  width: 60%;
  transform: skewY(-10deg);
  transform-origin: top left;

  p {
    color: #555;
  }
`

export const ContactLink = styled(ButtonLink)`
  color: var(--black);
  background: var(--white);
  border: 1px solid var(--primary);
  letter-spacing: initial;
  font-weight: bold;
  padding: ${rhythm(1 / 3)};
`
