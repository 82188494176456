import React from 'react'
import photoIcon from '../../../../static/svg/image.svg'
import { ISanityPhotoMin, ISiteCopy } from '../../../@types'
import { PageURLs } from '../../../vars'
import { SkewedContainer } from '../SkewedContainer'
import { Carousel } from './Carousel'
import { ShowcaseInner, ShowcaseOuter } from './styles'
import { ContactLink } from '../Introduction/styles'

type ShowcaseCopy = Pick<ISiteCopy, 'recentPhotosHeader' | 'recentPhotosCTAText'>

interface IProps extends ShowcaseCopy {
  showcaseAssets: ISanityPhotoMin[]
}

export function Showcase({ showcaseAssets, recentPhotosCTAText, recentPhotosHeader }: IProps) {
  return (
    <SkewedContainer
      outerComponent={ShowcaseOuter}
      innerComponent={ShowcaseInner}
      clockwise={false}
    >
      <h2>{recentPhotosHeader}</h2>
      <Carousel photoAssets={showcaseAssets} />
      <ContactLink to={PageURLs.Photos}>
        {recentPhotosCTAText} <img src={photoIcon} alt='Photo icon' />
      </ContactLink>
    </SkewedContainer>
  )
}
