import React, { useState } from 'react'

import backArrowIcon from '../../../../../static/svg/chevron-left.svg'
import forwardArrowIcon from '../../../../../static/svg/chevron-right.svg'

import { ISanityPhotoMin } from '../../../../@types'
import { useSwipe } from '../../../../hooks/useSwipe'
import {
  BackArrow,
  Container,
  ForwardArrow,
  Photos,
  PhotoWrapper,
  ShowcasePhoto,
} from './styles'

interface IProps {
  photoAssets: ISanityPhotoMin[]
}

export function Carousel({ photoAssets }: IProps) {
  const [currentIndex, setCurrentIndex] = useState(1)

  const touchHandlers = useSwipe(next, prev, false)

  const assetsLength = photoAssets.length

  function next() {
    if (currentIndex < assetsLength - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  function prev() {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const photos = photoAssets.map((asset, index) => {
    if (!asset.photo.asset.fluid) {
      return null
    }

    const photoIndex = 0 - (currentIndex - index)

    const handleClick =
      photoIndex < 0 ? prev : photoIndex > 0 ? next : undefined

    const isCurrent = index === currentIndex

    const relativeIndex = Math.abs(index - currentIndex)

    return (
      <PhotoWrapper
        key={asset.photoTitle}
        index={photoIndex}
        isCurrent={isCurrent}
        onClick={handleClick}
        relativeIndex={relativeIndex}
      >
        <ShowcasePhoto
          alt={asset.photoTitle}
          fluidAsset={asset.photo.asset.fluid}
          isCurrent={isCurrent}
          aspectRatio={asset.photo.asset.fluid.aspectRatio}
        />
      </PhotoWrapper>
    )
  })

  return (
    <Container {...touchHandlers}>
      <Photos>{photos}</Photos>
      {currentIndex > 0 && (
        <BackArrow onClick={prev}>
          <img src={backArrowIcon} alt='Back arrow icon' />
        </BackArrow>
      )}
      {currentIndex < assetsLength - 1 && (
        <ForwardArrow onClick={next}>
          <img src={forwardArrowIcon} alt='Forward arrow icon' />
        </ForwardArrow>
      )}
    </Container>
  )
}
