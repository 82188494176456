import { graphql } from 'gatsby'
import * as React from 'react'

import { IHomePageQuery } from '../@types'
import { Home } from '../components/HomePage'

interface IProps {
  data: IHomePageQuery
}

export default function Index({ data }: IProps) {
  return <Home data={data} />
}

export const query = graphql`
  query {
    sanityLandingPageBackground {
      image {
        asset {
          fluid(maxWidth: 3000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityIntroPhoto {
      introPhoto {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityPhoto(filter: { showcase: { eq: true } }) {
      nodes {
        photo {
          asset {
            fluid(maxWidth: 720) {
              ...GatsbySanityImageFluid
            }
          }
        }
        photoTitle
      }
    }
    sanitySiteCopy {
      siteHeader
      siteDescription
      recentPhotosHeader
      recentPhotosCTAText
      mainCTAText
      introductionHeader
      introductionDescription
      introductionCTAText
    }
  }
`
