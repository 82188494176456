import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled, { keyframes } from 'styled-components'
import { ButtonLink } from '../../../globalStyles'
import { rhythm } from '../../../utils/typography'
import { mediaQueries } from '../../../vars'

export const Hero = styled.div`
  height: 90vh;
  width: 100vw;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const HeroContent = styled.section`
  max-width: 85vw;
  margin-top: 1em;

  @media ${mediaQueries.portrait.tablet}, ${mediaQueries.landscape.tablet} {
    max-width: 60vw;
  }

  @media ${mediaQueries.general.desktop} {
    margin-left: 3em;
  }
`

const buttonHover = keyframes`
  from {
    background-position: 0;
  }
  to {
    background-position: 4rem;
  }
`

export const GoToPhotosLink = styled(ButtonLink)`
  background-color: #00000077;
  color: var(--white);
  font-weight: bold;

  :hover {
    background-image: linear-gradient(
      135deg,
      #ffffff11 25%,
      transparent 25%,
      transparent 50%,
      #ffffff11 50%,
      #ffffff11 75%,
      transparent 75%,
      transparent
    );
    color: rgb(255, 255, 255);
    animation: 5s linear 0s infinite normal none running ${buttonHover};
    background-size: 2rem 2rem;
  }
`

const arrowAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translateY(10px);
  }

  40% {
    transform: translate(0, 0);
  }
`

export const ScrollDownLink = styled(AnchorLink)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin-bottom: ${rhythm(1)};
  width: min-content;
  align-self: center;

  img {
    max-width: 5rem;
    margin-bottom: ${rhythm(1 / 4)};
    animation: 3s infinite ${arrowAnimation};
  }
`
