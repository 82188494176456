import styled from 'styled-components'

import { IconButton } from '../../../../globalStyles'
import { mediaQueries } from '../../../../vars'
import { Image } from '../../../common/Image'
import { rhythm } from '../../../../utils/typography'

export const Container = styled.div`
  --base-height: 25vh;
  margin-left: calc(-1 * var(--section-mar-left));
  margin-bottom: ${rhythm(1)};
  width: 100vw;
  height: var(--base-height);
  display: flex;
  justify-content: center;
  position: relative;
`

export const Photos = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  max-width: 720px;
`

interface IPhotoWrapper {
  index: number
  isCurrent: boolean
  relativeIndex: number
}

export const PhotoWrapper = styled.div<IPhotoWrapper>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: ${({ index, relativeIndex }) =>
    `translate(calc(75% * ${index})) scale(${1 - relativeIndex * 0.15})`};
  z-index: ${({ isCurrent }) => (isCurrent ? 100 : 0)};
  opacity: ${({ isCurrent }) => (isCurrent ? 1 : 0.7)};
  transition: all 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 720px;
  max-height: 100%;

  @media ${mediaQueries.general.desktop} {
    transform: ${({ index, relativeIndex }) =>
      `translate(calc(75% * ${index})) scale(${1.1 - relativeIndex * 0.15})`};
  }
`

interface IShowcasePhoto {
  isCurrent: boolean
  aspectRatio: number
}

export const ShowcasePhoto = styled(Image)<IShowcasePhoto>`
  --image-height: ${({ aspectRatio }) =>
    `calc(var(--base-height) / ${aspectRatio})`};
  height: var(--image-height);
  width: ${({ aspectRatio }) => `calc(var(--image-height) * ${aspectRatio})`};
  box-shadow: ${({ isCurrent }) =>
    isCurrent ? 'var(--box-shadow-heavy)' : 'var(--box-shadow)'};
  border-radius: 5px;
`

const Button = styled(IconButton)`
  position: absolute;
  top: 45%;
`

export const BackArrow = styled(Button)`
  left: 0;
`

export const ForwardArrow = styled(Button)`
  right: 0;
`
