import React from 'react'

import peopleIcon from '../../../../static/svg/people.svg'

import { IFluidPhoto, ISiteCopy } from '../../../@types'
import { PageURLs } from '../../../vars'
import { Background, ContactLink, Content } from './styles'

type IntroCopy = Pick<ISiteCopy, 'introductionCTAText' | 'introductionDescription' | 'introductionHeader'>

interface IProps extends IntroCopy {
  portrait: IFluidPhoto
}

export function Introduction({ portrait, introductionCTAText, introductionDescription, introductionHeader }: IProps) {
  return (
    <Background fluid={portrait} alt='Photo of Larry Hilderman'>
      <Content id='introduction'>
        <h2>{introductionHeader}</h2>
        <p>{introductionDescription}</p>
        <ContactLink to={PageURLs.Contact}>
          {introductionCTAText} <img src={peopleIcon} alt='Send icon' />
        </ContactLink>
      </Content>
    </Background>
  )
}
