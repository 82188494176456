import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, IconLink } from './styles'

interface IData {
  allSanitySocialMedia: {
    nodes: Array<{
      url: string,
      title: string,
      icon: {
        asset: {
          url: string
        }
      }
    }>
  }
}

export function SocialLinks() {
  const data: IData = useStaticQuery(graphql`
      {
          allSanitySocialMedia {
              nodes {
                  url
                  icon {
                      asset {
                          url
                      }
                  }
              }
          }
      }
  `)

  const links = data.allSanitySocialMedia.nodes.map((node) => {
    return (
      <IconLink
        href={node.url}
        target='_blank'
        rel='noopener noreferrer'
        key={node.title + node.url}
      >
        <img src={node.icon.asset.url} alt={`${node.title} icon`} />
      </IconLink>
    )
  })

  return (
    <Container>{links}</Container>
  )
}
