import styled from 'styled-components'
import { rhythm } from '../../../utils/typography'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { mediaQueries } from '../../../vars'

export const Container = styled.div`
  width: 70%;
  align-self: center;
  display: flex;
  justify-content: space-around;
  margin-bottom: -${rhythm(1 / 2)};
`

export const IconLink = styled(OutboundLink)`
  img {
    height: 1.5rem;
    margin: 0;

    @media ${mediaQueries.general.desktop} {
      height: 2rem;
    }
  }
`