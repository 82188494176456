import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import { mediaQueries } from '../../vars'
import { rhythm } from '../../utils/typography'

export const tan10Deg = Math.tan(Math.PI / 18)

interface IShowContent {
  showContent: boolean
}

export const Container = styled.div<IShowContent>`
  opacity: ${({ showContent }) => (showContent ? 1 : 0)};
  transition: opacity 1s;
  font-size: 1rem;

  h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.7em;
  }

  p {
    font-size: 1em;
    margin-bottom: 1em;
  }

  a {
    font-size: 1.1em;
  }

  --section-mar-left: ${rhythm(1)};

  section {
    margin-left: var(--section-mar-left);
  }

  @media ${mediaQueries.portrait.tablet}, ${mediaQueries.landscape.tablet} {
    font-size: 1.3rem;
    --section-mar-left: ${rhythm(3 / 2)};
  }

  @media ${mediaQueries.general.desktop} {
    font-size: 1.5rem;
    --section-mar-left: ${rhythm(5 / 2)};
  }

  @media (min-width: 2000px) {
    font-size: 1.7rem;
    --section-mar-left: ${rhythm(6)};
  }
`

export const Background = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 44% center;
  background-size: cover;
  z-index: -100;
  transition: opacity 1s;
`

export const Backdrop = styled.div`
  z-index: -101;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
`
