import styled from 'styled-components'

import { mediaQueries } from '../../../vars'
import { tan10Deg } from '../styles'

export const ShowcaseOuter = styled.div`
  height: 70vh;
  width: 100vw;
  background: var(--black-tl);
  color: var(--white);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (orientation: landscape) {
    height: 100vh;
  }
`

export const ShowcaseInner = styled.section`
  width: 100vw;
  margin-top: calc(-100vw * ${tan10Deg});
  height: 100%;
  max-height: calc(100% - (100vw * ${tan10Deg}));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  @media ${mediaQueries.landscape.handheld} {
    max-width: 100vmin;
    margin-top: calc(-100vw * ${tan10Deg} / 2);
    max-height: 80%;
  }

  @media ${mediaQueries.portrait.tablet}, ${mediaQueries.landscape.tablet} {
    margin-top: calc(-100vw * ${tan10Deg} / 2);
  }
`
