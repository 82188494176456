import React, { ReactElement } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

const skewAmt = '10deg'

interface IProps {
  children: ReactElement | ReactElement[]
  clockwise: boolean
  outerComponent: AnyStyledComponent
  innerComponent: AnyStyledComponent
  id?: string
}

export function SkewedContainer({
  clockwise,
  outerComponent,
  innerComponent,
  children,
  id,
}: IProps) {
  return (
    <Outer id={id ? id : ''} clockwise={clockwise} as={outerComponent}>
      <Inner clockwise={clockwise} as={innerComponent}>
        {children}
      </Inner>
    </Outer>
  )
}

interface IClockwise {
  clockwise: boolean
}

const Outer = styled.div<IClockwise>`
  --skew: ${({ clockwise }) => (clockwise ? skewAmt : `-${skewAmt}`)};
  transform: skewY(var(--skew));
  transform-origin: top left;
`

const Inner = styled.div<IClockwise>`
  --skew: ${({ clockwise }) => (clockwise ? `-${skewAmt}` : skewAmt)};
  transform: skewY(var(--skew));
  transform-origin: top left;
`
