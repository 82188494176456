import { Link } from 'gatsby'
import styled from 'styled-components'
import { rhythm } from '../../../utils/typography'
import { mediaQueries } from '../../../vars'
import { tan10Deg } from '../styles'

export const FooterOuter = styled.footer`
  height: 30vh;
  width: 100vw;
  background: var(--white-tl);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  padding-top: calc(100vw * ${tan10Deg} / 2);

  @media ${mediaQueries.landscape.handheld} {
    height: 60vh;
    align-items: center;
    justify-content: center;
  }

  @media ${mediaQueries.landscape.tablet} {
    height: 40vh;
    align-items: center;
  }

  @media ${mediaQueries.general.desktop} {
    height: 50vh;
  }
`

export const FooterInner = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 70%;

  @media ${mediaQueries.landscape.handheld},
    ${mediaQueries.landscape.tablet},
    ${mediaQueries.general.desktop} {
    max-width: 50%;
  }

  @media ${mediaQueries.portrait.tablet},
    ${mediaQueries.landscape.tablet},
    ${mediaQueries.general.desktop} {
    font-size: 1.3rem;
  }
`

export const PageLinks = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${rhythm(1 / 2)};

  @media ${mediaQueries.general.desktop} {
    margin-bottom: ${rhythm(1)};
  }
`

export const FooterLink = styled(Link)`
  color: var(--black);
  text-decoration: none;
  margin: 0 ${rhythm(1 / 2)};
`


